<template>
  <div>
    <!-- 沉浸式头部 -->
    <nav-bar header-title="超鹿全民健身季" backColor="#fff" :show-back="true" :header-back="newAppBack" />
    <!-- 预告 -->
    <div class="marketing-preview col-start-center">
      <img :src="item" v-for="item in list" :key="item" style="width: 100%" alt="">
    </div>

  </div>
</template>
<script>
import { newAppBack } from '@/lib/appMethod'

import navBar from '@/components/nav-bar/nav-bar'
import userMixin from '@/mixin/userMixin'
import headerMixin from '@/mixin/headerMixin'

export default {
  components: {
    navBar,
  },
  mixins: [userMixin, headerMixin],
  data() {
    return {
      list: [
        "https://img.chaolu.com.cn/storeAdmin/teacher/2024-07-22/pro/867726361909329920.jpg",
        "https://img.chaolu.com.cn/storeAdmin/teacher/2024-07-22/pro/867726361045270528.jpg",
        "https://img.chaolu.com.cn/storeAdmin/teacher/2024-07-30/pro/870618324581302272.jpg",
        "https://img.chaolu.com.cn/storeAdmin/teacher/2024-07-22/pro/867726361405980672.jpg",
        "https://img.chaolu.com.cn/storeAdmin/teacher/2024-07-22/pro/867726361754107904.jpg",
        "https://img.chaolu.com.cn/storeAdmin/teacher/2024-07-22/pro/867726362223902720.jpg",
        "https://img.chaolu.com.cn/storeAdmin/teacher/2024-07-22/pro/867726361938690048.jpg",
        "https://img.chaolu.com.cn/storeAdmin/teacher/2024-07-22/pro/867726361938690049.jpg",
        "https://img.chaolu.com.cn/storeAdmin/teacher/2024-07-22/pro/867726362215514112.jpg"
      ],
    }
  },
  computed: {},
  async created() { },
  mounted() { },
  methods: {
    newAppBack,
  }
}
</script>
<style lang="less" scoped>
.marketing-preview {
  min-height: 100vh;
}
</style>
